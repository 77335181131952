<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane :label="item"  v-for="(item, index) in arr"  :key="index">
        <el-row v-if="index==0">
          <el-col :span="24">
            <span>药品名称</span><span :titie='item.value'>{{ goodsinfo.name }}</span>
          </el-col>
          <el-col :span="24">
            <span>商品编号</span><span :titie='item.value'>{{ goodsinfo.productSn }}</span>
          </el-col>
          <el-col :span="24">
            <span>药品规格</span><span :titie='item.value'>{{ goodsinfo.specification }}</span>
          </el-col>
          <el-col :span="24">
            <span>生产厂家</span><span :titie='item.value'>{{ goodsinfo.manufacturerName }}</span>
          </el-col>
          <el-col :span="24">
            <span>医保代码</span><span :titie='item.value'>{{ goodsinfo.insuranceCode }}</span>
          </el-col>
          <el-col :span="24" v-for="(item, index) in entries" :key="index">
            <span>{{ item.name }}</span><span :titie='item.value'>{{ item.value }}</span>
          </el-col>
        </el-row>
        <el-row v-if="index==1">
          <!-- v-for="(item,index) in goodsinfo.albumPics.split(',')" :key="index" -->
          <el-col :span="24">
              <div v-html="goodsinfo.description" ></div>
              <!-- <img  :src="item" alt="index">  -->
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "DetailInfo",
  data() {
    return {
      arr: ["商品参数", "商品详情图"],
      activeName: 0,
      entries:'',
   
    };
  },
   props: {
    goodsinfo:{
         required: true,
      // type: Object,
    }
   },
  components: {},
  methods: {
    handleClick() {
      // tab, event
    },
   
  },
  watch: {
   
  },
created(){
  console.log('.parameterValues.parameterValues',this.goodsinfo)
  if(this.goodsinfo.parameterValues!="" && this.goodsinfo.parameterValues!=undefined){
    
    if(this.goodsinfo.length!=0){

      // console.log(JSON.parse(this.goodsinfo.parameterValues))
      this.entries=JSON.parse(this.goodsinfo.parameterValues)[0].entries;
    }
  }
   

  },
  mounted() {
  },
};
</script>
<style lang="less">
.detailInfo {
  .el-tabs__header {
    font-weight: bold;
    height: 50px;
    background: #f6f6f6;
    margin: 0;
    border: 0;
    .el-tabs__nav {
      border: none;
      .el-tabs__item {
        color: #666;
        font-size: 16px;
        display: inline-block;
        width: 120px;
        text-align: center;
        line-height: 50px;
        height: 50px;
        border: none;
      }
      .el-tabs__item.is-active {
        background: #ff3333;
        color: #fff;
      }
    }
  }
  .el-tab-pane {
    padding: 13px 50px;
    .el-col.el-col-24 {
      line-height:32px;
      font-size: 15px;
      img{
        width: 100%;
      }
      span:nth-child(1) {
        color: #666;
        width: 110px;
        margin-right:20px;
        display:block;
        float: left;
      }
      span:nth-child(2) {
        color: #333;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // overflow: hidden;
        display:block;
        float: left;
         margin-right:20px;
        width: calc(100% - 150px);
      }
    }
  }
}
</style>
<style lang="less" scoped>
@import "~style/index.less";
.detailInfo {
  padding-bottom: 16px;
}
</style>
